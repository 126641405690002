<template>
  <div class="fluid container">
    <div class="p-grid p-fluid">
      <div class="p-col-2 alignRight">Modalità</div>
      <div class="p-col-4">
        <Dropdown
          v-model="currentConnectionMode"
          :options="connectionModes"
          optionLabel="desc"
          placeholder="Seleziona modalità"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
        connectionModes: 'configuration/connectionModes',
    }),
    currentConnectionMode: {
      get() {
        return this.$store.getters['configuration/currentConnectionMode'];
      },
      set(newConnMode) {
        this.$store.dispatch('configuration/changeConnectionMode', newConnMode);
      }
    }
  },
};
</script>
<style scoped>
    alignRight {
        text-align:right
    }
</style>
